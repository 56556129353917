import { axiosInstance } from "../instance"

export const GET_INTEGRATION_LIST = () =>
  axiosInstance.get(`/user-integrations?page=1&limit=100`)

export const GET_SINGLE_INTEGRATION = id =>
  axiosInstance.get(`/user-integrations/${id}`)

export const GET_INTEGRATION_TYPES = () =>
  axiosInstance.get(`/integration-types`)

export const GET_INTEGRATION_TYPE_DATA = id =>
  axiosInstance.get(`/integration-types/${id}`)

export const CREATE_INTEGRATION = params =>
  axiosInstance.post("/user-integrations", params)

export const EDIT_INTEGRATION = (id, params) =>
  axiosInstance.put(`/user-integrations/${id}`, params)

export const DELETE_INTEGRATION = id =>
  axiosInstance.delete(`/user-integrations/${id}`)

export const GET_INTEGRATIONS_RECORDS = id =>
  axiosInstance.get(`/user-integrations/${id}/records`)

export const GET_INTEGRATION_ACCESS_RIGHTS = () =>
  axiosInstance.get("/integration-access-rights?page=1&limit=10")

export const GET_DATA_INTEGRATION_ACCESS_RIGHTS = id =>
  axiosInstance.get(`/integration-access-rights/${id}`)

export const INTEGRATIONS_LOAD_RECORDS = params =>
  axiosInstance.post("/user-integrations/load-records", params)
