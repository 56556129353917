import { axiosInstance } from "../instance"

export const CREATE_PAYMENT = params =>
  axiosInstance.post("/user-payments", params)

export const GET_PAYMENT_LIST = () => axiosInstance.get("/user-payments")

export const GET_PAYMENT_DATA = id => axiosInstance.get(`/user-payments/${id}`)

export const APPROVE_PAYMENT = id =>
  axiosInstance.post(`/user-payments/${id}/approve`)
