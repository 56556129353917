import React, { createContext } from "react"
import data from "../data.json"

export const StaticDataContext = createContext("")

const StaticDataContextProvider = ({ children }) => {
  const tariffs = data.tariff.items
    .sort((a, b) => a.days - b.days)
    .filter(x => x.id != 4)
  const dbTypes = data.dbTypes

  const value = { tariffs, dbTypes }

  return (
    <StaticDataContext.Provider value={value}>
      {children}
    </StaticDataContext.Provider>
  )
}
export default StaticDataContextProvider
