import React, { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { Link } from "gatsby"

export default function DocsNav({ nav }) {
  const { isInitialized } = useContext(AppContext)
  let path = isInitialized ? window.location.pathname : ""
  return (
    <>
      <h4>База знаний</h4>
      <nav
        className={"d-flex flex-column gap-2"}
        style={{ marginRight: "24px", maxWidth: "300px" }}
      >
        {nav.length > 0 &&
          nav.map((item, index) => (
            <Link
              key={index}
              className={`nav-link rounded-2 ${
                path.includes(item.url) ? "bg-primary text-light" : ""
              }`}
              style={{ padding: "5px 12px" }}
              to={`/docs/${item.url}`}
            >
              {item.title}
            </Link>
          ))}
      </nav>
    </>
  )
}
