import { axiosInstance } from "../instance"

export const CREATE_FILTER = params =>
  axiosInstance.post(`/user-integration-filters`, params)

export const DELETE_FILTER = id =>
  axiosInstance.delete(`/user-integration-filters/${id}`)

export const GET_FILTER_DATA = id =>
  axiosInstance.get(`/user-integration-filters/${id}`)

export const EDIT_FILTER = (id, params) =>
  axiosInstance.put(`/user-integration-filters/${id}`, params)

export const GET_FILTER_LIST = integration_id =>
  axiosInstance.get(
    `/user-integration-filters?page=1&limit=10&from_integration_id=${integration_id}`
  )

export const CHANGE_FILTER_ACTIVITY = id =>
  axiosInstance.post(`/user-integration-filters/${id}/toggle-active`)
