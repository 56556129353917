// extracted by mini-css-extract-plugin
export var community = "Footer-module--community--f53bd";
export var createBy = "Footer-module--createBy--774f2";
export var divider = "Footer-module--divider--ee6f5";
export var doc = "Footer-module--doc--979dd";
export var footerContainer = "Footer-module--footerContainer--839fd";
export var info = "Footer-module--info--b01f5";
export var logo = "Footer-module--logo--a1cb2";
export var nav = "Footer-module--nav--6a5d3";
export var social = "Footer-module--social--a953f";
export var support = "Footer-module--support--37cdb";
export var underlineFooter = "Footer-module--underlineFooter--97d37";