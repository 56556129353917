import React, { createContext } from "react"
import data from "../data.json"

export const IntegrationContext = createContext("")

const IntegrationContextProvider = ({ children }) => {
  const accessRights = data.accessRights
  const integrationTypes = data.integrationTypes.map(item => {
    removeNullProperties(item)
    if (item.id === 5) {
      delete item.table_field_labels.form_fields
    }
    return item
  })

  function removeNullProperties(obj) {
    // Проверяем, является ли obj объектом и не является ли null
    if (obj !== null && typeof obj === "object") {
      // Проходим по всем свойствам объекта
      for (const key in obj) {
        // Рекурсивно обрабатываем, если свойство тоже объект
        if (typeof obj[key] === "object") {
          removeNullProperties(obj[key])
        }
        // Удаляем свойство, если его значение равно null
        if (obj[key] === null) {
          delete obj[key]
        }
      }
    }
  }

  const value = { integrationTypes, accessRights }

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  )
}
export default IntegrationContextProvider
