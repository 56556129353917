import * as auth from "./auth"
import * as databaseTypes from "./databaseTypes"
import * as tariff from "./tariff"
import * as userDatabases from "./userDatabases"
import * as userIntegrations from "./userIntegrations"
import * as userIntegrationsFilter from "./userIntegrationsFilter"
import * as payment from "./userPayments"

const api = {
  auth,
  tariff,
  payment,
  databaseTypes,
  userDatabases,
  userIntegrations,
  userIntegrationsFilter,
}

export default api
