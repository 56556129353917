import { axiosInstance } from "../instance"

export const SIGN_UP = params => axiosInstance.post("/users/signup", params)

export const SIGN_IN = params => axiosInstance.post("/users/signin", params)

export const GET_ME = () => axiosInstance.get("/users/me")
export const CHANGE_PASSWORD = params =>
  axiosInstance.post("/users/me/change-password", params)

export const PASSWORD_RECOVERY_REQUEST = params =>
  axiosInstance.post("/user-password-recovery-requests/", params)
export const PASSWORD_RECOVERY_REQUEST_CONFIRM = params =>
  axiosInstance.post("/user-password-recovery-requests/confirm", params)
export const PASSWORD_RECOVERY_REQUEST_COMPLETE = params =>
  axiosInstance.post("/user-password-recovery-requests/complete", params)
