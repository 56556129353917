import React, { createContext, useEffect, useState } from "react"
import { axiosInstance } from "../api/instance"
import { navigate } from "gatsby"
import { route } from "../constants/route"
import useClient from "../hook/useClient"
import { Spinner } from "react-bootstrap"
import api from "../api"

export const AppContext = createContext("")

const AppContextProvider = ({ children }) => {
  const router = navigate
  const [isInitialized, setIsInitialized] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)
  const [profileInfo, setProfileInfo] = useState(null)

  const logout = () => {
    delete axiosInstance.defaults.headers.common.Authorization
    localStorage.removeItem("accessTokenNolimCloud")
    localStorage.removeItem("refreshTokenNolimCloud")
    localStorage.removeItem("profileInfo")
    setProfileInfo(null)
    navigate(route.home)
  }
  const isClient = useClient()
  useEffect(() => {
    if (!isClient) return

    const fetchProfile = async () => {
      try {
        const resMe = await api.auth.GET_ME()
        if (typeof resMe !== "string") {
          localStorage.setItem("profileInfo", JSON.stringify(resMe.item))
          setProfileInfo(resMe.item)
        }
      } catch (error) {
        console.error("Error fetching profile:", error)
        setProfileInfo(null)
      }
    }
    fetchProfile()
  }, [isClient])

  useEffect(() => {
    if (!isClient) return

    const storedProfile = localStorage.getItem("profileInfo")

    if (!isInitialized) {
      setIsInitialized(true)
    }

    if (isClient && !storedProfile) {
      const currentPath = window.location.pathname
      const isExemptPath =
        currentPath === "/" ||
        [route.term, route.docs, route.politics, "404", "auth"].some(path =>
          currentPath.includes(path)
        )
      if (isInitialized && !isExemptPath) {
        navigate(route.login)
      }
    }
  }, [isClient, isInitialized, profileInfo])

  const value = {
    logout,
    router,
    requestLoading,
    setRequestLoading,
    profileInfo,
    setProfileInfo,
    setIsInitialized,
    isInitialized,
  }

  return (
    <AppContext.Provider value={value}>
      {children}
      {isInitialized ? (
        <></>
      ) : (
        <div
          className={
            "position-fixed min-vh-100 d-flex align-items-center bg-light justify-content-center  "
          }
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: 99999999999999999999,
          }}
        >
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </AppContext.Provider>
  )
}
export default AppContextProvider
