import { axiosInstance } from "../instance"

export const DB_CONNECTION_LIST = () => axiosInstance.get(`/user-databases`)

export const ADD_DB_CONNECTION = params =>
  axiosInstance.post("/user-databases", params)

export const EDIT_DB_CONNECTION = (id, params) => {
  return axiosInstance.put(`/user-databases/${id}`, params)
}

export const DELETE_DB_CONNECTION = id => {
  return axiosInstance.delete(`/user-databases/${id}`)
}

export const GET_DB_INFO = id => {
  return axiosInstance.get(`/user-databases/${id}`)
}

export const GET_DB_TABLE_LIST = id => {
  return axiosInstance.get(`/user-databases/${id}/tables`)
}

export const GET_DB_TABLE_FIELDS = (database_id, table_id) => {
  return axiosInstance.get(
    `/user-databases/${database_id}/tables/${table_id}/fields`
  )
}

export const GET_AVAILABLE_OPERATORS = database_id => {
  return axiosInstance.get(
    `/user-databases/${database_id}/table-field-filter-operators`
  )
}

export const CLEAR_CONNECTION_CACHE = id => {
  return axiosInstance.post(`/user-databases/${id}/clear-cache`)
}
